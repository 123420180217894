import React from "react";
import Layout from "../components/Layout";

const SubPage = () => {
  return (
    <Layout>
      <section>
        <div className="container">
          <p>SUB PAGE</p>
        </div>
      </section>
    </Layout>
  );
};

export default SubPage;
